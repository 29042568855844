
































































































































































/*import LeaderboardEntry from '@/components/LeaderboardEntry.vue';*/
import {Component, Vue} from "vue-property-decorator";
import Aside from "@/components/Aside.vue";

@Component({
    name: "Leaderboard",
    components:{ Aside }
})
export default class Leaderboard extends Vue {
    protected isOpen = true;
}
